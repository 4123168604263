import {
  AuthApi,
  CampaignApi,
  Configuration,
  ContactApi,
  DashboardApi,
  FolderApi,
  NoticeApi,
  OpenApi,
  ReminderApi,
  RoleApi,
  TemplateApi,
  UserApi,
} from './swagger'
import { BehaviorSubject } from 'rxjs'
import { User } from './swagger/models'
import jwtDecode from 'jwt-decode'
export class ApiController {
  private static instance: ApiController
  private config: Configuration = new Configuration()
  public authApi: AuthApi = new AuthApi()
  public openApi: OpenApi = new OpenApi()
  public userApi: UserApi = new UserApi()
  public campaignApi: CampaignApi = new CampaignApi()
  public contactApi: ContactApi = new ContactApi()
  public dashboardMgmtApi: DashboardApi = new DashboardApi()
  public noticeApi: NoticeApi = new NoticeApi()
  public reminderApi: ReminderApi = new ReminderApi()
  public roleApi: RoleApi = new RoleApi()
  public templateApi: TemplateApi = new TemplateApi()
  public folderApi: FolderApi = new FolderApi()

  public user: User | null = null
  public user$ = new BehaviorSubject<User | null>(null)
  public UserLoggedIn: User | null = null
  public UserLoggedIn$ = new BehaviorSubject<User | null>(null)

  private constructor() {
    const token = this.getSavedToken()

    if (token) {
      this.initApis(token)
      this.loadSavedUser()
    } else {
      this.clearToken()
    }
  }

  public updateUserProfile(user: User | null): void {
    this.user = user
    this.user$.next(user)
    this.saveUser(user)
  }

  public updateLoggedInStatus(user: User | null): void {
    this.UserLoggedIn = user
    this.UserLoggedIn$.next(user)
  }

  private saveUser(user: User | null): void {
    localStorage.setItem('USER', JSON.stringify(user))
  }

  public static getInstance(): ApiController {
    if (!ApiController.instance) {
      ApiController.instance = new ApiController()
    }
    return ApiController.instance
  }

  private loadSavedUser(): User {
    const strUser = localStorage.getItem('USER')
    if (!strUser) {
      return {}
    } else {
      const user = JSON.parse(strUser)
      this.user = user
      this.updateLoggedInStatus(user)
      this.user$.next(user)
      return user
    }
  }

  private saveToken(token: string | null): void {
    // tslint:disable-next-line: no-console
    token = token ? token : JSON.stringify(null)
    localStorage.setItem('TOKEN', token)
    localStorage.setItem('TOKEN_TS', JSON.stringify(Date.now()))
  }

  public getSavedToken(): string {
    const base64token = localStorage.getItem('TOKEN')
    if (!base64token) {
      return ''
    }
    const token: any = jwtDecode(base64token)
    if (token?.exp * 1000 <= new Date().getTime()) {
      return ''
    }
    return base64token
  }

  public setToken(token: string | null): void {
    this.saveToken(token)
    this.setApiConfig(token)
  }

  public clearToken(): void {
    this.config = {}
    this.updateUserProfile(null)
    this.setToken(null)
    this.updateLoggedInStatus(null)
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('TOKEN_TS')
    localStorage.removeItem('UUID')
    localStorage.removeItem('USER')
    this.setApiConfig('')
    localStorage.clear()
  }

  public initApis(token: string | null) {
    this.setToken(token)
  }

  private setApiConfig(token: string | null): void {
    this.config.baseOptions = {
      headers: {
        authorization: token,
      },
    }
    this.authApi = new AuthApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.openApi = new OpenApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.userApi = new UserApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.campaignApi = new CampaignApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.contactApi = new ContactApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.dashboardMgmtApi = new DashboardApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.noticeApi = new NoticeApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.reminderApi = new ReminderApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.roleApi = new RoleApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.templateApi = new TemplateApi(this.config, process.env.REACT_APP_BASE_PATH)
    this.folderApi = new FolderApi(this.config, process.env.REACT_APP_BASE_PATH)
    // Add other api services here
  }
}
