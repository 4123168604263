/* eslint-disable import/no-anonymous-default-export */

import { FolderAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Attachment, Folder, SubFolder } from '../../swagger/models'

interface FolderObject {
  folders: Folder[]
  attachments?: Attachment[]
  subFolders: SubFolder[]
  folder?: Folder
  subFolder?: SubFolder
  shouldShowLoader: boolean
  folderAction?: string
}

const initialState = {
  folders: [],
  folder: {},
  subFolder: {},
  subFolders: [],
  attachments: [],
  shouldShowLoader: false,
  folderAction: undefined,
}

const folderReducer = (state: FolderObject = initialState, action: FolderAction) => {
  switch (action.type) {
    case ActionType.LIST_FOLDERS:
      return {
        ...state,
        folders: action.folders,
      }
    case ActionType.LIST_SUBFOLDERS:
      return {
        ...state,
        subFolders: action.subFolders,
      }
    case ActionType.LIST_ATTACHMENTS:
      return {
        ...state,
        attachments: action.attachments,
      }
    case ActionType.GET_FOLDER:
      return {
        ...state,
        folder: action.folder,
      }
    case ActionType.GET_SUBFOLDER:
      return {
        ...state,
        subFolder: action.subfolder,
      }
    case ActionType.CREATE_FOLDER:
      return {
        ...state,
        folder: action.createFolder,
        folders: [
          ...state.folders,
          action.createFolder
        ]
      }
    case ActionType.CREATE_SUBFOLDER:
      return {
        ...state,
        subFolder: action.createSubFolder,
        subFolders: [
          ...state.subFolders,
          action.createSubFolder
        ]
      }
    case ActionType.UPLOAD_ATTACHMENT:
      return {
        ...state,
        attachments: [
          ...state.attachments!,
          action.attachment,
        ]
      }
    case ActionType.DELETE_FOLDER:
      const updatedFoldersArray = state.folders
      updatedFoldersArray?.splice(action.folderIndex, 1)
      return {
        ...state,
        folders: updatedFoldersArray,
        folderAction: 'DELETE_FOLDER'
      }
    case ActionType.DELETE_SUBFOLDER:
      const updatedSubFoldersArray = state.subFolders
      updatedSubFoldersArray?.splice(action.subFolderIndex, 1)
      return {
        ...state,
        subFolders: updatedSubFoldersArray,
        folderAction: 'DELETE_SUBFOLDER'
      }
      case ActionType.DELETE_ATTACHMENT:
        const updatedAttachmentsArray = state.attachments
        updatedAttachmentsArray?.splice(action.attachmentIndex, 1)
        return {
          ...state,
          attachments: updatedAttachmentsArray,
          folderAction: 'DELETE_ATTACHMENT'
        }
    default:
      return state
  }
}

export default folderReducer
