import { ActionType, SnackbarActionType } from '../action-types'
import { ApiController } from '../../apiController'
import { Dispatch } from 'redux'
import { ChangePassword, LoginRequest } from '../../swagger/models'
import { CommonAction, SnackbarAction } from '../actions/index'

export const login = (loginRequest: LoginRequest) => {
  return async (dispatch: Dispatch<CommonAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const userData = await ApiController.getInstance().openApi.login(loginRequest)
      ApiController.getInstance().updateUserProfile(userData.data)
      ApiController.getInstance().initApis(userData.data.token)
      ApiController.getInstance().updateLoggedInStatus(userData.data)
      dispatch({
        type: ActionType.LOGIN,
        user: userData.data,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const forgotPassword = (email: string) => {
  return async (dispatch: Dispatch<CommonAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const userData = await ApiController.getInstance().openApi.forgotPassword(email)
      dispatch({
        type: ActionType.FORGOT_PASSWORD,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Reset Password link sent successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const changePassword = (changePasswordObject: ChangePassword) => {
  return async (dispatch: Dispatch<CommonAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const userData = await ApiController.getInstance().openApi.resetPassword(changePasswordObject)
      dispatch({
        type: ActionType.CHANGE_PASSWORD,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Password updated successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}