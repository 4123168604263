import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiController } from '../../../apiController'
import { LoginRequest, User } from '../../../swagger/models'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openActionCreators } from '../../../state/index'
import { Files, Eye, EyeSlash } from 'phosphor-react'
import { Link } from 'react-router-dom'
import SnackbarHelper from '../../common-module/snackbar-helper'
import Loader from '../../common-module/loader'
import store from '../../../state/store'

function Login() {
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    email: '',
    password: '',
    isRememberme: true,
  })
  const [showInput, setShowInput] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { login } = bindActionCreators(openActionCreators, dispatch)

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((userLoggedIn: User | null) => {
      if (userLoggedIn) {
        navigate('/admin/dashboard')
      }
    })
    store.subscribe(() => {
      const { shouldShowLoader } = store.getState().authReducer
      setShowLoader(shouldShowLoader)
    })
  }, [])

  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setLoginRequest({
      ...loginRequest,
      [name]: value,
    })
  }

  async function onSubmit(e: any) {
    e.preventDefault()
    if (loginRequest.isRememberme) {
      localStorage.setItem('email', loginRequest.email)
    }
    login(loginRequest)
    return false
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  return (
    <Fragment>
      <div className='flex flex-col h-full w-full align-middle items-center mt-40 gap-6'>
        <SnackbarHelper />
        {showLoader && <Loader />}
        <Files size={44} weight="thin" />
        <p className='text-xl text-center'>Welcome to Sama's<br />Notice Management System</p>
        {showInput === false ?
          <button aria-label='Login' className='hover:bg-black bg-gray-900 text-white text-sm py-4 px-28 rounded-lg'
            onClick={() => setShowInput(true)}
          >Login</button> :
          <div className='flex flex-col gap-2'>

            <form onSubmit={onSubmit} className='flex flex-col gap-2'>
              <label htmlFor="email" className='text-sm'>Email Address</label>
              <input aria-label="Email" type='email' name='email' id='email' onChange={onInputChange} className='rounded-lg border border-black border-solid' />
              <label htmlFor="password" className='text-sm'>Password</label>
              <div className='flex flex-row gap-0.5 border border-solid border-black rounded-lg'>
                <input type={passwordType} name="password" id='password' onChange={onInputChange} className='rounded-lg border-none w-full' />
                <button onClick={togglePassword} type='button' className='px-3'>
                  {passwordType === 'password' ? <Eye size={20} weight="thin" /> : <EyeSlash size={20} weight="thin" />}
                </button>
              </div>
              <button aria-label='Login to Continue' type='submit' className='hover:bg-black bg-gray-900 text-white text-sm py-4 px-28 rounded-lg'>
                Login to Continue
              </button>
              <div className='flex flex-row gap-2 items-center'>
                <input aria-label="Keep me logged In" type='checkbox' id='logggedin' className='bg-grey-blurry border-none' />
                <label>Keep me logged in</label>
              </div>
              <Link aria-label='Forgot Password' to="/forgot-password">Forgot Password?</Link>
            </form>
          </div>
        }
      </div>
    </Fragment>
  )
}

export default Login