import React, { Fragment, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import ChangePasswordFunction from "./Components/Auth-module/Change-password/Change-password";
import ForgotPassword from "./Components/Auth-module/Forgot-password/Forgot-password";
import Login from "./Components/Auth-module/Login/Login";
import Loader from "./Components/common-module/loader";

const Admin = React.lazy(
  () => import("./Components/Admin-Module/Admin-Module")
);

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/change-password/:token"
            element={<ChangePasswordFunction />}
          />
          <Route
            path="/admin/*"
            element={
              <Suspense fallback={<Loader />}>
                <Admin />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate replace={true} to="login" />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
