/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Campaign } from '../models';
/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the details of campaign
         * @summary Add campaign specific details
         * @param {Campaign} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCampaign: async (body: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addCampaign.');
            }
            const localVarPath = `/admin/campaign-management/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of campaigns opened inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignOpen: async (campaignId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaign-management/campaign_open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of campaigns sent inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignSent: async (campaignId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaign-management/campaign_sent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of campaign
         * @summary Delete campaign specific details
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign: async (campaignId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            if (campaignId === null || campaignId === undefined) {
                throw new RequiredError('campaignId','Required parameter campaignId was null or undefined when calling deleteCampaign.');
            }
            const localVarPath = `/admin/campaign-management/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of campaigns inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (campaignId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaign-management/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the campaigns inside organisation
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [campaignStatus] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns: async (fromDate?: string, toDate?: string, campaignStatus?: string, search?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaign-management/list_campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (campaignStatus !== undefined) {
                localVarQueryParameter['campaignStatus'] = campaignStatus;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the recent campaigns inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentCampaigns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/campaign-management/recent_campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send details of campaigns inside organisation to individual notice contact
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotice: async (campaignId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            if (campaignId === null || campaignId === undefined) {
                throw new RequiredError('campaignId','Required parameter campaignId was null or undefined when calling sendNotice.');
            }
            const localVarPath = `/admin/campaign-management/send_notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of campaign
         * @summary Update campaign specific details
         * @param {Campaign} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (body: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCampaign.');
            }
            const localVarPath = `/admin/campaign-management/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add the details of campaign
         * @summary Add campaign specific details
         * @param {Campaign} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCampaign(body: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).addCampaign(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get details of campaigns opened inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignOpen(campaignId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignOpen(campaignId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get details of campaigns sent inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignSent(campaignId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignSent(campaignId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of campaign
         * @summary Delete campaign specific details
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCampaign(campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).deleteCampaign(campaignId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get details of campaigns inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(campaignId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaign(campaignId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the campaigns inside organisation
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [campaignStatus] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaigns(fromDate?: string, toDate?: string, campaignStatus?: string, search?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Campaign>>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).listCampaigns(fromDate, toDate, campaignStatus, search, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the recent campaigns inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentCampaigns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Campaign>>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).recentCampaigns(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send details of campaigns inside organisation to individual notice contact
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotice(campaignId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).sendNotice(campaignId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of campaign
         * @summary Update campaign specific details
         * @param {Campaign} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(body: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).updateCampaign(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Add the details of campaign
         * @summary Add campaign specific details
         * @param {Campaign} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCampaign(body: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).addCampaign(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of campaigns opened inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignOpen(campaignId?: number, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).campaignOpen(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of campaigns sent inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignSent(campaignId?: number, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).campaignSent(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of campaign
         * @summary Delete campaign specific details
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(campaignId: number, options?: any): AxiosPromise<void> {
            return CampaignApiFp(configuration).deleteCampaign(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of campaigns inside organisation
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(campaignId?: number, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).getCampaign(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the campaigns inside organisation
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [campaignStatus] 
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns(fromDate?: string, toDate?: string, campaignStatus?: string, search?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Array<Campaign>> {
            return CampaignApiFp(configuration).listCampaigns(fromDate, toDate, campaignStatus, search, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the recent campaigns inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentCampaigns(options?: any): AxiosPromise<Array<Campaign>> {
            return CampaignApiFp(configuration).recentCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send details of campaigns inside organisation to individual notice contact
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotice(campaignId: number, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).sendNotice(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of campaign
         * @summary Update campaign specific details
         * @param {Campaign} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(body: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).updateCampaign(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * Add the details of campaign
     * @summary Add campaign specific details
     * @param {Campaign} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public addCampaign(body: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).addCampaign(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get details of campaigns opened inside organisation
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignOpen(campaignId?: number, options?: any) {
        return CampaignApiFp(this.configuration).campaignOpen(campaignId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get details of campaigns sent inside organisation
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignSent(campaignId?: number, options?: any) {
        return CampaignApiFp(this.configuration).campaignSent(campaignId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of campaign
     * @summary Delete campaign specific details
     * @param {number} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public deleteCampaign(campaignId: number, options?: any) {
        return CampaignApiFp(this.configuration).deleteCampaign(campaignId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get details of campaigns inside organisation
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(campaignId?: number, options?: any) {
        return CampaignApiFp(this.configuration).getCampaign(campaignId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the campaigns inside organisation
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [campaignStatus] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public listCampaigns(fromDate?: string, toDate?: string, campaignStatus?: string, search?: string, limit?: number, offset?: number, options?: any) {
        return CampaignApiFp(this.configuration).listCampaigns(fromDate, toDate, campaignStatus, search, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the recent campaigns inside organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public recentCampaigns(options?: any) {
        return CampaignApiFp(this.configuration).recentCampaigns(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send details of campaigns inside organisation to individual notice contact
     * @param {number} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public sendNotice(campaignId: number, options?: any) {
        return CampaignApiFp(this.configuration).sendNotice(campaignId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of campaign
     * @summary Update campaign specific details
     * @param {Campaign} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public updateCampaign(body: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).updateCampaign(body, options).then((request) => request(this.axios, this.basePath));
    }
}
