/* eslint-disable import/no-anonymous-default-export */

import { CampaignAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Campaign } from '../../swagger/models'

interface CampaignObject {
  campaign?: Campaign
  campaignOpenReport?: Campaign
  campaignSentReport?: Campaign
  campaigns?: Campaign[]
  shouldShowLoader: boolean
  eventType?: string
}

const initialState = {
  campaign: {},
  campaignSentReport: {},
  campaignOpenReport: {},
  campaigns: [],
  shouldShowLoader: false,
  eventType: undefined
}

const campaignReducer = (state: CampaignObject = initialState, action: CampaignAction) => {
  switch (action.type) {
    case ActionType.GET_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
        eventType: 'GET_CAMPAIGN'
      };
    case ActionType.NEW_CAMPAIGN:
      return {
        ...state,
        campaign: {},
        eventType: 'NEW_CAMPAIGN'
      };
    case ActionType.ADD_CAMPAIGN:
      return {
        ...state,
        campaign: action.newCampaign,
        eventType: 'ADD_CAMPAIGN'
      };
    case ActionType.UPDATE_CAMPAIGN:
      return {
        ...state,
        campaign: action.updateCampaign,
        eventType: 'UPDATE_CAMPAIGN'
      };
    case ActionType.LIST_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.campaigns,
        eventType: 'LIST_CAMPAIGN'
      };
    case ActionType.SEND_CAMPAIGN:
      return {
        ...state,
        eventType: 'SEND_CAMPAIGN'
      };
    case ActionType.GET_CAMPAIGN_OPEN_REPORT:
      return {
        ...state,
        campaignOpenReport: action.campaign,
        eventType: 'GET_CAMPAIGN_OPEN_REPORT'
      };
    case ActionType.GET_CAMPAIGN_SENT_REPORT:
      return {
        ...state,
        campaignSentReport: action.campaign,
        eventType: 'GET_CAMPAIGN_SENT_REPORT'
      };
    default:
      return state;
  }
}

export default campaignReducer
