import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { ActionType, SnackbarActionType } from '../action-types'
import { SnackbarAction, DashboardAction } from '../actions'

export const listLatestCampaigns = () => {
    return async (dispatch: Dispatch<DashboardAction | SnackbarAction>) => {
      try {
        const campaigns = await (
          await ApiController.getInstance().dashboardMgmtApi.latestCampaigns()
        ).data
        dispatch({
          type: ActionType.LATEST_CAMPAIGNS,
          campaigns: campaigns,
        })
      } catch (error: any) {
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const noticeReport = () => {
    return async (dispatch: Dispatch<DashboardAction | SnackbarAction>) => {
      try {
        const noticeReports = await (
          await ApiController.getInstance().dashboardMgmtApi.noticeReport()
        ).data
        dispatch({
          type: ActionType.NOTICE_REPORT,
          noticeReports: noticeReports,
        })
      } catch (error: any) {
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }