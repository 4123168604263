/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Campaign } from '../models';
import { Notice } from '../models';
import { NoticeDashboardReport } from '../models';
import { NoticeResponse } from '../models';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the latest campaigns based of an organisation
         * @summary Get latest campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestCampaigns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/dashboard-management/latest_campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the latest notices response based of an organisation
         * @summary Get latest notices response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestResponses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/dashboard-management/latest_responses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the twelve month notices based of an organisation
         * @summary Get twelve month notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noticeReport: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/dashboard-management/notice_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the all notices based of an organisation
         * @summary Get all notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalNotices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/dashboard-management/total_notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the all templates based of an organisation
         * @summary Get all templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/dashboard-management/total_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the upcoming due date based of an organisation
         * @summary Get upcoming due date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upcomingDueDates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/dashboard-management/upcoming_dueDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get the latest campaigns based of an organisation
         * @summary Get latest campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latestCampaigns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Campaign>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).latestCampaigns(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the latest notices response based of an organisation
         * @summary Get latest notices response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latestResponses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeResponse>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).latestResponses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the twelve month notices based of an organisation
         * @summary Get twelve month notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noticeReport(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeDashboardReport>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).noticeReport(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the all notices based of an organisation
         * @summary Get all notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalNotices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).totalNotices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the all templates based of an organisation
         * @summary Get all templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).totalTemplates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the upcoming due date based of an organisation
         * @summary Get upcoming due date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upcomingDueDates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notice>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).upcomingDueDates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get the latest campaigns based of an organisation
         * @summary Get latest campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestCampaigns(options?: any): AxiosPromise<Array<Campaign>> {
            return DashboardApiFp(configuration).latestCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the latest notices response based of an organisation
         * @summary Get latest notices response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestResponses(options?: any): AxiosPromise<Array<NoticeResponse>> {
            return DashboardApiFp(configuration).latestResponses(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the twelve month notices based of an organisation
         * @summary Get twelve month notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noticeReport(options?: any): AxiosPromise<Array<NoticeDashboardReport>> {
            return DashboardApiFp(configuration).noticeReport(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the all notices based of an organisation
         * @summary Get all notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalNotices(options?: any): AxiosPromise<void> {
            return DashboardApiFp(configuration).totalNotices(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the all templates based of an organisation
         * @summary Get all templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalTemplates(options?: any): AxiosPromise<void> {
            return DashboardApiFp(configuration).totalTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the upcoming due date based of an organisation
         * @summary Get upcoming due date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upcomingDueDates(options?: any): AxiosPromise<Array<Notice>> {
            return DashboardApiFp(configuration).upcomingDueDates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * Get the latest campaigns based of an organisation
     * @summary Get latest campaigns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public latestCampaigns(options?: any) {
        return DashboardApiFp(this.configuration).latestCampaigns(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the latest notices response based of an organisation
     * @summary Get latest notices response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public latestResponses(options?: any) {
        return DashboardApiFp(this.configuration).latestResponses(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the twelve month notices based of an organisation
     * @summary Get twelve month notices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public noticeReport(options?: any) {
        return DashboardApiFp(this.configuration).noticeReport(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the all notices based of an organisation
     * @summary Get all notices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public totalNotices(options?: any) {
        return DashboardApiFp(this.configuration).totalNotices(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the all templates based of an organisation
     * @summary Get all templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public totalTemplates(options?: any) {
        return DashboardApiFp(this.configuration).totalTemplates(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the upcoming due date based of an organisation
     * @summary Get upcoming due date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public upcomingDueDates(options?: any) {
        return DashboardApiFp(this.configuration).upcomingDueDates(options).then((request) => request(this.axios, this.basePath));
    }
}
