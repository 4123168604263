/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChangePassword } from '../models';
import { LoginRequest } from '../models';
import { User } from '../models';
/**
 * OpenApi - axios parameter creator
 * @export
 */
export const OpenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/open/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling login.');
            }
            const localVarPath = `/open/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset password of the user.
         * @summary reset user's password
         * @param {ChangePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (body: ChangePassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/open/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {string} countryCode 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp: async (countryCode: string, phone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryCode' is not null or undefined
            if (countryCode === null || countryCode === undefined) {
                throw new RequiredError('countryCode','Required parameter countryCode was null or undefined when calling sendOtp.');
            }
            // verify required parameter 'phone' is not null or undefined
            if (phone === null || phone === undefined) {
                throw new RequiredError('phone','Required parameter phone was null or undefined when calling sendOtp.');
            }
            const localVarPath = `/open/send_otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenApi - functional programming interface
 * @export
 */
export const OpenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).forgotPassword(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).login(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Reset password of the user.
         * @summary reset user's password
         * @param {ChangePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(body: ChangePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).resetPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {string} countryCode 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOtp(countryCode: string, phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpenApiAxiosParamCreator(configuration).sendOtp(countryCode, phone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpenApi - factory interface
 * @export
 */
export const OpenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(email: string, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).forgotPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for Admin
         * @summary Login API for user
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginRequest, options?: any): AxiosPromise<User> {
            return OpenApiFp(configuration).login(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset password of the user.
         * @summary reset user's password
         * @param {ChangePassword} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body: ChangePassword, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).resetPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Send forgot password link to the user.
         * @summary change user's password link
         * @param {string} countryCode 
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp(countryCode: string, phone: string, options?: any): AxiosPromise<void> {
            return OpenApiFp(configuration).sendOtp(countryCode, phone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpenApi - object-oriented interface
 * @export
 * @class OpenApi
 * @extends {BaseAPI}
 */
export class OpenApi extends BaseAPI {
    /**
     * Send forgot password link to the user.
     * @summary change user's password link
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public forgotPassword(email: string, options?: any) {
        return OpenApiFp(this.configuration).forgotPassword(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login for Admin
     * @summary Login API for user
     * @param {LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public login(body: LoginRequest, options?: any) {
        return OpenApiFp(this.configuration).login(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Reset password of the user.
     * @summary reset user's password
     * @param {ChangePassword} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public resetPassword(body: ChangePassword, options?: any) {
        return OpenApiFp(this.configuration).resetPassword(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send forgot password link to the user.
     * @summary change user's password link
     * @param {string} countryCode 
     * @param {string} phone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenApi
     */
    public sendOtp(countryCode: string, phone: string, options?: any) {
        return OpenApiFp(this.configuration).sendOtp(countryCode, phone, options).then((request) => request(this.axios, this.basePath));
    }
}
