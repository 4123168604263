import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { Folder, SubFolder } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { SnackbarAction, FolderAction } from '../actions'

export const listFolders = () => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const folders = await (
        await ApiController.getInstance().folderApi.allFolders()
      ).data
      dispatch({
        type: ActionType.LIST_FOLDERS,
        folders: folders,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const listSubFolders = (folderId?: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const subFolders = await (
        await ApiController.getInstance().folderApi.allSubfolders(folderId)
      ).data
      dispatch({
        type: ActionType.LIST_SUBFOLDERS,
        subFolders: subFolders,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const listAttachments = (folderId?: number, subFolderId?: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const attachments = await (
        await ApiController.getInstance().folderApi.listAttachments(folderId, subFolderId)
      ).data
      dispatch({
        type: ActionType.LIST_ATTACHMENTS,
        attachments: attachments,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getFolder = (folderId: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const folder = await (
        await ApiController.getInstance().folderApi.getFolder(folderId)
      ).data
      dispatch({
        type: ActionType.GET_FOLDER,
        folder: folder,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const createFolder = (folderDetails: Folder) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const folder = await (
        await ApiController.getInstance().folderApi.addFolder(folderDetails)
      ).data
      dispatch({
        type: ActionType.CREATE_FOLDER,
        createFolder: folder,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Folder created successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getSubFolder = (subFolderId: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const subFolder = await (
        await ApiController.getInstance().folderApi.getSubFolder(subFolderId)
      ).data
      dispatch({
        type: ActionType.GET_SUBFOLDER,
        subfolder: subFolder,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const createSubFolder = (subFolderDetails: SubFolder) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const subFolder = await (
        await ApiController.getInstance().folderApi.addSubFolder(subFolderDetails)
      ).data
      dispatch({
        type: ActionType.CREATE_SUBFOLDER,
        createSubFolder: subFolder,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Sub Folder created successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const uploadAttachment = (file: any, folderId?: number, subfolderId?: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const attachment = await (
        await ApiController.getInstance().folderApi.uploadAttachment(file, folderId, subfolderId)
      ).data
      dispatch({
        type: ActionType.UPLOAD_ATTACHMENT,
        attachment: attachment,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Attachment uploaded successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteFolder = (folderId: number, folderIndex: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().folderApi.deleteFolder(folderId)
      ).data
      dispatch({
        type: ActionType.DELETE_FOLDER,
        folderId: folderId,
        folderIndex: folderIndex
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Folder deleted successfully',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteSubFolder = (subFolderId: number, subFolderIndex: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().folderApi.deleteSubFolder(subFolderId)
      ).data
      dispatch({
        type: ActionType.DELETE_SUBFOLDER,
        subFolderId: subFolderId,
        subFolderIndex: subFolderIndex
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Sub Folder deleted successfully',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteAttachment = (attachmentId: number, attachmentIndex: number) => {
  return async (dispatch: Dispatch<FolderAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().folderApi.deleteAttachment(attachmentId)
      ).data
      dispatch({
        type: ActionType.DELETE_ATTACHMENT,
        attachmentId: attachmentId,
        attachmentIndex: attachmentIndex
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Attachment deleted successfully',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}