import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { openActionCreators } from '../../../state'
import store from '../../../state/store'
import { ChangePassword } from '../../../swagger/models'
import SnackbarHelper from '../../common-module/snackbar-helper'

function ChangePasswordFunction() {
  const [changePasswordObject, setChangePasswordObject] = useState<ChangePassword>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    token: ''
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const token: string = params.token!

  const { changePassword } = bindActionCreators(openActionCreators, dispatch)

  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setChangePasswordObject({
      ...changePasswordObject,
      [name]: value,
    })
  }

  async function onSubmit(e: any) {
    e.preventDefault()
    changePassword(changePasswordObject)
    return false
  }

  useEffect(()=> {
    setChangePasswordObject({
      ...changePasswordObject,
      token: token
    })
  }, [token])

  useEffect(()=> {
    store.subscribe(() => {
      const { passwordChanged } = store.getState().authReducer
      if (passwordChanged) {
        navigate('/login')
      }
    })
  }, [])

  return (
    <Fragment>
      <SnackbarHelper/>
    <div className='flex flex-col h-full w-full align-middle items-center mt-40 gap-6'>

      <div className='flex flex-col gap-2'>
        <form onSubmit={onSubmit} className='flex flex-col gap-2'>
          <label htmlFor="password" className='text-sm'>New Password</label>
          <input aria-label="New Password" type='password' name="newPassword" id='newPassword' onChange={onInputChange} className='rounded-lg border border-black border-solid' />
          <label htmlFor="password" className='text-sm'>Confirm Password</label>
          <input aria-label="Confirm new Password" type='password' name="confirmPassword" id='confirmPassword' onChange={onInputChange} className='rounded-lg border border-black border-solid' />
          <button aria-label='Reset Password' type='submit' className='bg-black text-white text-sm py-4 px-28 rounded-lg'>
            Reset Password
          </button>
          <Link aria-label='Login' to="/login">Login</Link>
        </form>
      </div>
    </div>
    </Fragment>
  )
}

export default ChangePasswordFunction