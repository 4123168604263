/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { NoticeTemplate } from '../models';
/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the details of organisation template
         * @summary add template specific details
         * @param {NoticeTemplate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTemplate: async (body: NoticeTemplate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addTemplate.');
            }
            const localVarPath = `/admin/template-management/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of organisation template.
         * @summary Delete template specific details
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (templateId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling deleteTemplate.');
            }
            const localVarPath = `/admin/template-management/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary delete template question specific details
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplateQuestion: async (questionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling deleteTemplateQuestion.');
            }
            const localVarPath = `/admin/template-management/template_question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get template specific details
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (templateId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling getTemplate.');
            }
            const localVarPath = `/admin/template-management/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the recent templates inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/template-management/list_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the recent templates inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/template-management/recent_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send test template notice to specified email ids
         * @param {number} templateId 
         * @param {string} emailIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestNotice: async (templateId: number, emailIds: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling sendTestNotice.');
            }
            // verify required parameter 'emailIds' is not null or undefined
            if (emailIds === null || emailIds === undefined) {
                throw new RequiredError('emailIds','Required parameter emailIds was null or undefined when calling sendTestNotice.');
            }
            const localVarPath = `/admin/template-management/send_test_notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            if (emailIds !== undefined) {
                localVarQueryParameter['emailIds'] = emailIds;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of organisation template
         * @summary Update template specific details
         * @param {NoticeTemplate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (body: NoticeTemplate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTemplate.');
            }
            const localVarPath = `/admin/template-management/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add the details of organisation template
         * @summary add template specific details
         * @param {NoticeTemplate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTemplate(body: NoticeTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeTemplate>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).addTemplate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of organisation template.
         * @summary Delete template specific details
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(templateId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).deleteTemplate(templateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary delete template question specific details
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplateQuestion(questionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).deleteTemplateQuestion(questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get template specific details
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(templateId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeTemplate>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).getTemplate(templateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the recent templates inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeTemplate>>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).listTemplates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the recent templates inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoticeTemplate>>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).recentTemplates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send test template notice to specified email ids
         * @param {number} templateId 
         * @param {string} emailIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestNotice(templateId: number, emailIds: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).sendTestNotice(templateId, emailIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of organisation template
         * @summary Update template specific details
         * @param {NoticeTemplate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(body: NoticeTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeTemplate>> {
            const localVarAxiosArgs = await TemplateApiAxiosParamCreator(configuration).updateTemplate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Add the details of organisation template
         * @summary add template specific details
         * @param {NoticeTemplate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTemplate(body: NoticeTemplate, options?: any): AxiosPromise<NoticeTemplate> {
            return TemplateApiFp(configuration).addTemplate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of organisation template.
         * @summary Delete template specific details
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateId: number, options?: any): AxiosPromise<void> {
            return TemplateApiFp(configuration).deleteTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary delete template question specific details
         * @param {number} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplateQuestion(questionId: number, options?: any): AxiosPromise<void> {
            return TemplateApiFp(configuration).deleteTemplateQuestion(questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get template specific details
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateId: number, options?: any): AxiosPromise<NoticeTemplate> {
            return TemplateApiFp(configuration).getTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the recent templates inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(options?: any): AxiosPromise<Array<NoticeTemplate>> {
            return TemplateApiFp(configuration).listTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the recent templates inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentTemplates(options?: any): AxiosPromise<Array<NoticeTemplate>> {
            return TemplateApiFp(configuration).recentTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send test template notice to specified email ids
         * @param {number} templateId 
         * @param {string} emailIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestNotice(templateId: number, emailIds: string, options?: any): AxiosPromise<void> {
            return TemplateApiFp(configuration).sendTestNotice(templateId, emailIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of organisation template
         * @summary Update template specific details
         * @param {NoticeTemplate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(body: NoticeTemplate, options?: any): AxiosPromise<NoticeTemplate> {
            return TemplateApiFp(configuration).updateTemplate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * Add the details of organisation template
     * @summary add template specific details
     * @param {NoticeTemplate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public addTemplate(body: NoticeTemplate, options?: any) {
        return TemplateApiFp(this.configuration).addTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of organisation template.
     * @summary Delete template specific details
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public deleteTemplate(templateId: number, options?: any) {
        return TemplateApiFp(this.configuration).deleteTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of organisation user based on it's email id
     * @summary delete template question specific details
     * @param {number} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public deleteTemplateQuestion(questionId: number, options?: any) {
        return TemplateApiFp(this.configuration).deleteTemplateQuestion(questionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of organisation user based on it's email id
     * @summary get template specific details
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplate(templateId: number, options?: any) {
        return TemplateApiFp(this.configuration).getTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the recent templates inside organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public listTemplates(options?: any) {
        return TemplateApiFp(this.configuration).listTemplates(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the recent templates inside organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public recentTemplates(options?: any) {
        return TemplateApiFp(this.configuration).recentTemplates(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send test template notice to specified email ids
     * @param {number} templateId 
     * @param {string} emailIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public sendTestNotice(templateId: number, emailIds: string, options?: any) {
        return TemplateApiFp(this.configuration).sendTestNotice(templateId, emailIds, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of organisation template
     * @summary Update template specific details
     * @param {NoticeTemplate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public updateTemplate(body: NoticeTemplate, options?: any) {
        return TemplateApiFp(this.configuration).updateTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }
}
