import { clearSnackbar } from '../../state/action-creators/snackbarActions'
import { Toast } from 'flowbite-react'
import { Check, X } from 'phosphor-react'
import { useState, useEffect } from 'react'
import store from '../../state/store'

export default function SnackbarHelper() {
  const [snackbarMsg, setSnackbarMessage]= useState<string>('')
  const [snackbarType, setSnackbarType]= useState<string|undefined>('')
  const [snackbarOpen, setSnackbarOpen]= useState<boolean|undefined>(false)

  const [show, setShow] = useState(true);

  function handleClose() {
    clearSnackbar()
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const { snackbarMessage, successSnackbarOpen, type } = store.getState().snackberReducer
      if (snackbarMessage){
        setShow(true)
        setSnackbarMessage(snackbarMessage)
      }
      setSnackbarType(type)
      setSnackbarOpen(successSnackbarOpen)
    })
    return unsubscribe;
  }, [show])

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 5000)
    return () => {
      clearSnackbar()
      clearTimeout(timeId)
    }
  }, [snackbarOpen])

  if (!show) {
    return null;
  }
  return (
    <div>
      {/*Success Snackbar */}
      {snackbarOpen && snackbarType === 'success' &&
        <Toast style={{ position: 'fixed', right: '0', top: '2%' }}>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
            <Check size={16} weight="thin" onClick={handleClose} />
          </div>
          <div className="ml-3 text-sm font-normal" aria-live='polite'>
            {snackbarMsg}
          </div>
          <Toast.Toggle />
        </Toast>
      }

      {/* failure snackbar */}
      {snackbarOpen && snackbarType === 'error' &&
        <Toast style={{ position: 'absolute', left: '50%', top: '10%'}}>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
            <X size={16} weight="thin" onClick={handleClose} />
          </div>
          <div className="ml-3 text-sm font-normal">
            {snackbarMsg}
          </div>
          <Toast.Toggle />
        </Toast>
      }
    </div>
  )
}
