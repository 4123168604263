/* eslint-disable import/no-anonymous-default-export */

import { TemplateAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { NoticeTemplate } from '../../swagger/models'

interface TemplateObject {
  template?: NoticeTemplate
  recentTemplates?: NoticeTemplate[]
  templates?: NoticeTemplate[]
  shouldShowLoader: boolean
  templateAction?: string
}

const initialState = {
  template: {},
  templates: [],
  recentTemplates: [],
  shouldShowLoader: false,
  templateAction: undefined
}

const templateReducer = (state: TemplateObject = initialState, action: TemplateAction) => {
  switch (action.type) {
    case ActionType.GET_TEMPLATE:
      return {
        ...state,
        template: action.noticeTemplate,
        templateAction: 'GET_TEMPLATE'
      }
    case ActionType.CREATE_TEMPLATE:
      return {
        ...state,
        template: action.createNoticeTemplate,
        templateAction: 'CREATE_TEMPLATE'
      }
    case ActionType.UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.updateNoticeTemplate,
        templateAction: 'UPDATE_TEMPLATE'
      }
    case ActionType.RECENT_TEMPLATES:
      return {
        ...state,
        recentTemplates: action.recentTemplates,
      }
    case ActionType.DELETE_TEMPLATE:
      const index = state.recentTemplates?.findIndex((template: NoticeTemplate) => template.templateId == action.templateId)
      const updatedRecentTemplatesArray = state.recentTemplates
      updatedRecentTemplatesArray?.splice(index!, 1)
      return {
        ...state,
        recentTemplates: updatedRecentTemplatesArray,
        templateAction: 'DELETE_TEMPLATE'
      }
    case ActionType.LIST_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      }
    default:
      return state
  }
}

export default templateReducer
