import { SnackbarAction } from './../actions/index'
import { Dispatch } from 'redux'
import { SnackbarActionType } from '../action-types'

export const showSuccessSnackbar = (message: string, timer?: number) => {
  return (dispatch: Dispatch<SnackbarAction>) => {
    dispatch({
      type: SnackbarActionType.SNACKBAR_SUCCESS,
      message,
      timer,
    })
  }
}

export const showErrorSnackbar = (message: string, timer?: number) => {
  return (dispatch: Dispatch<SnackbarAction>) => {
    dispatch({
      type: SnackbarActionType.SNACKBAR_FAILURE,
      message,
      timer,
    })
  }
}

export const clearSnackbar = () => {
  return (dispatch: Dispatch<SnackbarAction>) => {
    dispatch({
      type: SnackbarActionType.SNACKBAR_CLEAR,
    })
  }
}
