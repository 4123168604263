import { combineReducers } from 'redux'
import authReducer from './authReducer'
import campaignReducer from "./campaignReducer"
import contactReducer from "./contactReducer"
import dashboardReducer from "./dashboardReducer"
import noticeReducer from "./noticeReducer"
import templateReducer from "./templateReducer"
import folderReducer from "./folderReducer"
import snackberReducer from './snackbarReducer'

const reducers = combineReducers({
  authReducer,
  snackberReducer,
  campaignReducer,
  contactReducer,
  dashboardReducer,
  noticeReducer,
  templateReducer,
  folderReducer,
})

export default reducers

export type State = ReturnType<typeof reducers>
