import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { NoticeComment, Reminder } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { SnackbarAction, NoticeAction } from '../actions'

export const listNotices = () => {
    return async (dispatch: Dispatch<NoticeAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const notices = await (
          await ApiController.getInstance().noticeApi.listNotices()
        ).data
        dispatch({
          type: ActionType.LIST_NOTICES,
          notices: notices,
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const getNotice = (noticeId: number) => {
    return async (dispatch: Dispatch<NoticeAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const notice = await (
          await ApiController.getInstance().noticeApi.getNotice(noticeId)
        ).data
        dispatch({
          type: ActionType.GET_NOTICE,
          notice: notice,
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const createComment = (commentDetails: NoticeComment) => {
    return async (dispatch: Dispatch<NoticeAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const comment = await (
          await ApiController.getInstance().noticeApi.addComment(commentDetails)
        ).data
        dispatch({
          type: ActionType.ADD_COMMENT,
          comment: comment,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Comment added successfully',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const sendReminder = (reminderDetails: Reminder) => {
    return async (dispatch: Dispatch<NoticeAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const reminder = await (
          await ApiController.getInstance().noticeApi.sendNoticeReminder(reminderDetails)
        ).data
        dispatch({
          type: ActionType.SEND_REMINDER,
          reminder: reminder,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Reminder Sent successfully',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const sendReminderPreview = (reminderDetails: Reminder, emailIds: string) => {
    return async (dispatch: Dispatch<NoticeAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        await (
          await ApiController.getInstance().noticeApi.sendNoticeReminderPreview(reminderDetails, emailIds)
        ).data
        dispatch({
          type: ActionType.SEND_REMINDER_PREVIEW,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Reminder Preview Sent successfully',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const sendBulkReminder = (reminderDetails: Reminder, noticeIds: string) => {
    return async (dispatch: Dispatch<NoticeAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        await (
          await ApiController.getInstance().noticeApi.sendNoticeReminderBulk(reminderDetails, noticeIds)
        ).data
        dispatch({
          type: ActionType.SEND_BULK_REMINDER,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Reminder Sent successfully to all contacts',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }