import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { ActionType, SnackbarActionType } from '../action-types'
import { SnackbarAction, ReminderAction } from '../actions'
import { Reminder } from '../../swagger/models'

export const listReminders = () => {
    return async (dispatch: Dispatch<ReminderAction | SnackbarAction>) => {
      try {
        const reminders = await (
          await ApiController.getInstance().reminderApi.listReminders()
        ).data
        dispatch({
          type: ActionType.LIST_REMINDERS,
          reminders: reminders,
        })
      } catch (error: any) {
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const addReminder = (reminderDetails: Reminder) => {
    return async (dispatch: Dispatch<ReminderAction | SnackbarAction>) => {
      try {
        const reminder = await (
          await ApiController.getInstance().reminderApi.addReminder(reminderDetails)
        ).data
        dispatch({
          type: ActionType.ADD_REMINDER,
          reminder: reminder,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Reminder created successfully',
        })
      } catch (error: any) {
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }