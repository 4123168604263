/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Reminder } from '../models';
/**
 * ReminderApi - axios parameter creator
 * @export
 */
export const ReminderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the details of reminder
         * @summary Add reminder specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReminder: async (body: Reminder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addReminder.');
            }
            const localVarPath = `/admin/reminder-management/reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete the reminder of a specific user
         * @summary Complete the reminder of a specific user
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeReminder: async (reminderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reminderId' is not null or undefined
            if (reminderId === null || reminderId === undefined) {
                throw new RequiredError('reminderId','Required parameter reminderId was null or undefined when calling completeReminder.');
            }
            const localVarPath = `/admin/reminder-management/complete_reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reminderId !== undefined) {
                localVarQueryParameter['reminderId'] = reminderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of reminder
         * @summary Delete reminder specific details
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReminder: async (reminderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reminderId' is not null or undefined
            if (reminderId === null || reminderId === undefined) {
                throw new RequiredError('reminderId','Required parameter reminderId was null or undefined when calling deleteReminder.');
            }
            const localVarPath = `/admin/reminder-management/reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reminderId !== undefined) {
                localVarQueryParameter['reminderId'] = reminderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete the reminder of a specific user
         * @summary Complete the reminder of a specific user
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminder: async (reminderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reminderId' is not null or undefined
            if (reminderId === null || reminderId === undefined) {
                throw new RequiredError('reminderId','Required parameter reminderId was null or undefined when calling getReminder.');
            }
            const localVarPath = `/admin/reminder-management/reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reminderId !== undefined) {
                localVarQueryParameter['reminderId'] = reminderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the all reminders based of an organisation
         * @summary Get all reminders
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReminders: async (fromDate?: string, toDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/reminder-management/list_reminders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of reminder
         * @summary Update reminder specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReminder: async (body: Reminder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateReminder.');
            }
            const localVarPath = `/admin/reminder-management/reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReminderApi - functional programming interface
 * @export
 */
export const ReminderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add the details of reminder
         * @summary Add reminder specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReminder(body: Reminder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reminder>> {
            const localVarAxiosArgs = await ReminderApiAxiosParamCreator(configuration).addReminder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Complete the reminder of a specific user
         * @summary Complete the reminder of a specific user
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeReminder(reminderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reminder>> {
            const localVarAxiosArgs = await ReminderApiAxiosParamCreator(configuration).completeReminder(reminderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of reminder
         * @summary Delete reminder specific details
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReminder(reminderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReminderApiAxiosParamCreator(configuration).deleteReminder(reminderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Complete the reminder of a specific user
         * @summary Complete the reminder of a specific user
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReminder(reminderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reminder>> {
            const localVarAxiosArgs = await ReminderApiAxiosParamCreator(configuration).getReminder(reminderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the all reminders based of an organisation
         * @summary Get all reminders
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReminders(fromDate?: string, toDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reminder>>> {
            const localVarAxiosArgs = await ReminderApiAxiosParamCreator(configuration).listReminders(fromDate, toDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of reminder
         * @summary Update reminder specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReminder(body: Reminder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reminder>> {
            const localVarAxiosArgs = await ReminderApiAxiosParamCreator(configuration).updateReminder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReminderApi - factory interface
 * @export
 */
export const ReminderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Add the details of reminder
         * @summary Add reminder specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReminder(body: Reminder, options?: any): AxiosPromise<Reminder> {
            return ReminderApiFp(configuration).addReminder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete the reminder of a specific user
         * @summary Complete the reminder of a specific user
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeReminder(reminderId: number, options?: any): AxiosPromise<Reminder> {
            return ReminderApiFp(configuration).completeReminder(reminderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of reminder
         * @summary Delete reminder specific details
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReminder(reminderId: number, options?: any): AxiosPromise<void> {
            return ReminderApiFp(configuration).deleteReminder(reminderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete the reminder of a specific user
         * @summary Complete the reminder of a specific user
         * @param {number} reminderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReminder(reminderId: number, options?: any): AxiosPromise<Reminder> {
            return ReminderApiFp(configuration).getReminder(reminderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the all reminders based of an organisation
         * @summary Get all reminders
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReminders(fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<Reminder>> {
            return ReminderApiFp(configuration).listReminders(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of reminder
         * @summary Update reminder specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReminder(body: Reminder, options?: any): AxiosPromise<Reminder> {
            return ReminderApiFp(configuration).updateReminder(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReminderApi - object-oriented interface
 * @export
 * @class ReminderApi
 * @extends {BaseAPI}
 */
export class ReminderApi extends BaseAPI {
    /**
     * Add the details of reminder
     * @summary Add reminder specific details
     * @param {Reminder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public addReminder(body: Reminder, options?: any) {
        return ReminderApiFp(this.configuration).addReminder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Complete the reminder of a specific user
     * @summary Complete the reminder of a specific user
     * @param {number} reminderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public completeReminder(reminderId: number, options?: any) {
        return ReminderApiFp(this.configuration).completeReminder(reminderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of reminder
     * @summary Delete reminder specific details
     * @param {number} reminderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public deleteReminder(reminderId: number, options?: any) {
        return ReminderApiFp(this.configuration).deleteReminder(reminderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Complete the reminder of a specific user
     * @summary Complete the reminder of a specific user
     * @param {number} reminderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public getReminder(reminderId: number, options?: any) {
        return ReminderApiFp(this.configuration).getReminder(reminderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the all reminders based of an organisation
     * @summary Get all reminders
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public listReminders(fromDate?: string, toDate?: string, options?: any) {
        return ReminderApiFp(this.configuration).listReminders(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of reminder
     * @summary Update reminder specific details
     * @param {Reminder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public updateReminder(body: Reminder, options?: any) {
        return ReminderApiFp(this.configuration).updateReminder(body, options).then((request) => request(this.axios, this.basePath));
    }
}
