import { Files } from 'phosphor-react'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { openActionCreators } from '../../../state'
import SnackbarHelper from '../../common-module/snackbar-helper'


function ForgotPassword() {
  const [email, setEmail] = useState<string>(localStorage.getItem('email') || '')
  const dispatch = useDispatch()

  const { forgotPassword } = bindActionCreators(openActionCreators, dispatch)

  const onInputChange = (e: any) => {
    const { value } = e.target
    setEmail(value)
  }
  async function onSubmit(e: any) {
    e.preventDefault()
    forgotPassword(email)
    return false
  }

  return (
    <Fragment>
    <SnackbarHelper/>
    <div className='flex flex-col h-full w-full align-middle items-center mt-40 gap-6'>
      <Files size={44} weight="thin" />
      <p className='text-xl text-center'>Welcome to Sama's<br />Notice Management System</p>        
        <div className='flex flex-col gap-2'>
          <form onSubmit={onSubmit} className='flex flex-col gap-2'>
            <label htmlFor="email" className='text-sm'>Email Address</label>
            <input type='email' name='email' id='email' onChange={onInputChange} className='rounded-lg border border-black border-solid' />
            <button disabled={!email} aria-label='Send me Link' type='submit' className='hover:bg-black bg-gray-900 text-white text-sm py-4 px-28 rounded-lg'>
              Send me link
            </button>
          <Link aria-label='Login' to="/login">Login</Link>
          </form>
        </div>
    </div>
    </Fragment>
  )
}

export default ForgotPassword