/* eslint-disable import/no-anonymous-default-export */

import { NoticeAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Notice } from '../../swagger/models'

interface NoticeObject {
  notice?: Notice
  comment?: Comment
  shouldShowLoader: boolean
}

const initialState = {
  notice: {},
  shouldShowLoader: false,
}

const noticeReducer = (state: NoticeObject = initialState, action: NoticeAction) => {
  switch (action.type) {
    case ActionType.GET_NOTICE:
      return {
        ...state,
        notice: action.notice,
      }
    case ActionType.ADD_COMMENT:
      return {
        ...state,
        comment: action.comment,
        notice: {
          ...state.notice,
          NoticeComments: [
            ...state.notice?.NoticeComments,
            action.comment
          ]
        }
      }
    case ActionType.SEND_REMINDER:
      return {
        ...state,
        notice: {
          ...state.notice,
          Reminders: [
            ...state.notice?.Reminders,
            action.reminder
          ]
        }
      }
    default:
      return state
  }
}

export default noticeReducer
