import { ActionType, SnackbarActionType } from '../action-types'
import { ApiController } from '../../apiController'
import { Dispatch } from 'redux'
import { AuthAction, SnackbarAction } from '../actions/index'
import { User } from '../../swagger/models'

export const updateProfile = (userDetails: User) => {
  return async (dispatch: Dispatch<AuthAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const userData = await ApiController.getInstance().authApi.updateProfile(userDetails)
      ApiController.getInstance().updateUserProfile(userData.data)
      dispatch({
        type: ActionType.UPDATE_PROFILE,
        user: userData.data,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}