/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Contact } from '../models';
import { ExcelUploadErrors } from '../models';
import { ExcelUploadResult } from '../models';
import { TotalContact } from '../models';
/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the details of contact
         * @summary Add contact specific details
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContact: async (body: Contact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addContact.');
            }
            const localVarPath = `/admin/contact-management/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate contacts excel for admin
         * @summary Generate contacts excel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactExcel: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/contact-management/contact_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of contact
         * @summary Delete contact specific details
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (contactId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new RequiredError('contactId','Required parameter contactId was null or undefined when calling deleteContact.');
            }
            const localVarPath = `/admin/contact-management/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of contact based on it's contact id
         * @summary Get contact specific details
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: async (contactId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new RequiredError('contactId','Required parameter contactId was null or undefined when calling getContact.');
            }
            const localVarPath = `/admin/contact-management/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the recent contact inside organisation
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts: async (limit?: number, offset?: number, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/contact-management/list_contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the recent contact inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalContacts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/contact-management/total_contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of contact
         * @summary Update contact specific details
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (body: Contact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContact.');
            }
            const localVarPath = `/admin/contact-management/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uplod the excel file of contacts
         * @param {string} file 
         * @param {boolean} includeFirstRow 
         * @param {boolean} dryRun Dry run the database transactions to see the results
         * @param {string} columnMappings 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContactExcel: async (file: string, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, tags?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadContactExcel.');
            }
            // verify required parameter 'includeFirstRow' is not null or undefined
            if (includeFirstRow === null || includeFirstRow === undefined) {
                throw new RequiredError('includeFirstRow','Required parameter includeFirstRow was null or undefined when calling uploadContactExcel.');
            }
            // verify required parameter 'dryRun' is not null or undefined
            if (dryRun === null || dryRun === undefined) {
                throw new RequiredError('dryRun','Required parameter dryRun was null or undefined when calling uploadContactExcel.');
            }
            // verify required parameter 'columnMappings' is not null or undefined
            if (columnMappings === null || columnMappings === undefined) {
                throw new RequiredError('columnMappings','Required parameter columnMappings was null or undefined when calling uploadContactExcel.');
            }
            const localVarPath = `/admin/contact-management/upload_contact_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeFirstRow !== undefined) {
                localVarQueryParameter['includeFirstRow'] = includeFirstRow;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (columnMappings !== undefined) {
                localVarQueryParameter['columnMappings'] = columnMappings;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add the details of contact
         * @summary Add contact specific details
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContact(body: Contact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).addContact(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Generate contacts excel for admin
         * @summary Generate contacts excel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactExcel(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).contactExcel(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of contact
         * @summary Delete contact specific details
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(contactId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).deleteContact(contactId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of contact based on it's contact id
         * @summary Get contact specific details
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContact(contactId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).getContact(contactId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the recent contact inside organisation
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContacts(limit?: number, offset?: number, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).listContacts(limit, offset, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the recent contact inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalContacts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalContact>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).totalContacts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of contact
         * @summary Update contact specific details
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(body: Contact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).updateContact(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Uplod the excel file of contacts
         * @param {string} file 
         * @param {boolean} includeFirstRow 
         * @param {boolean} dryRun Dry run the database transactions to see the results
         * @param {string} columnMappings 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadContactExcel(file: string, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, tags?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExcelUploadResult>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).uploadContactExcel(file, includeFirstRow, dryRun, columnMappings, tags, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Add the details of contact
         * @summary Add contact specific details
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContact(body: Contact, options?: any): AxiosPromise<Contact> {
            return ContactApiFp(configuration).addContact(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate contacts excel for admin
         * @summary Generate contacts excel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactExcel(options?: any): AxiosPromise<string> {
            return ContactApiFp(configuration).contactExcel(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of contact
         * @summary Delete contact specific details
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(contactId: number, options?: any): AxiosPromise<void> {
            return ContactApiFp(configuration).deleteContact(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of contact based on it's contact id
         * @summary Get contact specific details
         * @param {number} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact(contactId: number, options?: any): AxiosPromise<Contact> {
            return ContactApiFp(configuration).getContact(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the recent contact inside organisation
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<Array<Contact>> {
            return ContactApiFp(configuration).listContacts(limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the recent contact inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalContacts(options?: any): AxiosPromise<TotalContact> {
            return ContactApiFp(configuration).totalContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of contact
         * @summary Update contact specific details
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(body: Contact, options?: any): AxiosPromise<Contact> {
            return ContactApiFp(configuration).updateContact(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uplod the excel file of contacts
         * @param {string} file 
         * @param {boolean} includeFirstRow 
         * @param {boolean} dryRun Dry run the database transactions to see the results
         * @param {string} columnMappings 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContactExcel(file: string, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, tags?: string, options?: any): AxiosPromise<ExcelUploadResult> {
            return ContactApiFp(configuration).uploadContactExcel(file, includeFirstRow, dryRun, columnMappings, tags, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * Add the details of contact
     * @summary Add contact specific details
     * @param {Contact} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public addContact(body: Contact, options?: any) {
        return ContactApiFp(this.configuration).addContact(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Generate contacts excel for admin
     * @summary Generate contacts excel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactExcel(options?: any) {
        return ContactApiFp(this.configuration).contactExcel(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of contact
     * @summary Delete contact specific details
     * @param {number} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public deleteContact(contactId: number, options?: any) {
        return ContactApiFp(this.configuration).deleteContact(contactId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of contact based on it's contact id
     * @summary Get contact specific details
     * @param {number} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public getContact(contactId: number, options?: any) {
        return ContactApiFp(this.configuration).getContact(contactId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the recent contact inside organisation
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public listContacts(limit?: number, offset?: number, search?: string, options?: any) {
        return ContactApiFp(this.configuration).listContacts(limit, offset, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the recent contact inside organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public totalContacts(options?: any) {
        return ContactApiFp(this.configuration).totalContacts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of contact
     * @summary Update contact specific details
     * @param {Contact} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public updateContact(body: Contact, options?: any) {
        return ContactApiFp(this.configuration).updateContact(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Uplod the excel file of contacts
     * @param {string} file 
     * @param {boolean} includeFirstRow 
     * @param {boolean} dryRun Dry run the database transactions to see the results
     * @param {string} columnMappings 
     * @param {string} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public uploadContactExcel(file: string, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, tags?: string, options?: any) {
        return ContactApiFp(this.configuration).uploadContactExcel(file, includeFirstRow, dryRun, columnMappings, tags, options).then((request) => request(this.axios, this.basePath));
    }
}
