import { SnackbarActionType } from '../action-types'
import { SnackbarAction } from '../actions'

interface SnackbarInterface {
  successSnackbarOpen?: boolean
  snackbarMessage?: string
  type?: string
  timer?: number
}

const initialState = {
  successSnackbarOpen: false,
  snackbarMessage: '',
  type: undefined,
  timer: 2000,
}

const snackbarReducer = (state: SnackbarInterface = initialState, action: SnackbarAction) => {
  switch (action.type) {
    case SnackbarActionType.SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        type: 'success',
        snackbarMessage: action.message,
        timer: action.timer,
      }
    case SnackbarActionType.SNACKBAR_FAILURE:
      return {
        ...state,
        successSnackbarOpen: true,
        type: 'error',
        snackbarMessage: action.message,
        timer: action.timer,
      }
    case SnackbarActionType.SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        type: undefined,
        snackbarMessage: null,
      }
    default:
      return state
  }
}

export default snackbarReducer
