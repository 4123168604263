import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { Contact } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { SnackbarAction, ContactAction } from '../actions'

export const listContacts = (search?: string, limit?: number, offset?: number) => {
  return async (dispatch: Dispatch<ContactAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const contacts = await (
        await ApiController.getInstance().contactApi.listContacts(limit, offset, search)
      ).data
      dispatch({
        type: ActionType.LIST_CONTACTS,
        Contacts: contacts,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getContact = (contactId: number) => {
  return async (dispatch: Dispatch<ContactAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const contact = await (
        await ApiController.getInstance().contactApi.getContact(contactId)
      ).data
      dispatch({
        type: ActionType.GET_CONTACT,
        Contact: contact,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const createContact = (contactDetails: Contact) => {
  return async (dispatch: Dispatch<ContactAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const contact = await (
        await ApiController.getInstance().contactApi.addContact(contactDetails)
      ).data
      dispatch({
        type: ActionType.CREATE_CONTACT,
        Contact: contact,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Contact details added successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateContact = (contactDetails: Contact) => {
  return async (dispatch: Dispatch<ContactAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const contact = await (
        await ApiController.getInstance().contactApi.updateContact(contactDetails)
      ).data
      dispatch({
        type: ActionType.UPDATE_CONTACT,
        updateContact: contact,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Contact details updated successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const uploadContactExcelFile = (file: any, columnMappings: any, tags: any) => {
  return async (dispatch: Dispatch<ContactAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const contact = await (
        await ApiController.getInstance().contactApi.uploadContactExcel(file, false, false, columnMappings, tags)
      ).data
      dispatch({
        type: ActionType.UPLOAD_CONTACT,
        uploadResult: contact,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Contact excel imported successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteContact = (contactId: number, index: number) => {
  return async (dispatch: Dispatch<ContactAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().contactApi.deleteContact(contactId)
      ).data
      dispatch({
        type: ActionType.DELETE_CONTACT,
        index: index,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}