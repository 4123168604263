/* eslint-disable import/no-anonymous-default-export */

import { ContactAction } from '../actions/index'
import { ActionType } from '../action-types/index'
import { Contact, ExcelUploadResult } from '../../swagger/models'

interface ContactObject {
  contact?: Contact
  contacts?: Contact[]
  uploadResult?: ExcelUploadResult
  shouldShowLoader: boolean
  actionType: string
}

const initialState = {
  contact: {},
  uploadResult: {},
  shouldShowLoader: false,
  actionType: ''
}

const contactReducer = (state: ContactObject = initialState, action: ContactAction) => {
  switch (action.type) {
    case ActionType.GET_CONTACT:
      return {
        ...state,
        contact: action.Contact,
        actionType: 'GET_CONTACT'
      }
    case ActionType.CREATE_CONTACT:
      return {
        ...state,
        contact: action.Contact,
        actionType: 'CREATE_CONTACT'
      }
    case ActionType.UPDATE_CONTACT:
      return {
        ...state,
        contact: action.updateContact,
        actionType: 'UPDATE_CONTACT'
      }
    case ActionType.LIST_CONTACTS:
      return {
        ...state,
        contacts: action.Contacts,
        actionType: 'LIST_CONTACTS'
      }
    case ActionType.UPLOAD_CONTACT:
      return {
        ...state,
        uploadResult: action.uploadResult,
      }
      case ActionType.DELETE_CONTACT:
        const updatedContactsArray = state.contacts
        updatedContactsArray?.splice(action.index, 1)
        return {
          ...state,
          contacts: updatedContactsArray,
          actionType: 'DELETE_CONTACT'
        }
    default:
      return state
  }
}

export default contactReducer
