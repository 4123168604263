import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { Campaign } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { CampaignAction, SnackbarAction } from '../actions'

export const listCampaigns = (fromDate: string, toDate: string, campaignStatus: string, search: string, limit?: number, offset?: number) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaigns = await (
          await ApiController.getInstance().campaignApi.listCampaigns(fromDate, toDate, campaignStatus, search, limit, offset)
        ).data
        dispatch({
          type: ActionType.LIST_CAMPAIGNS,
          campaigns: campaigns,
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const getCampaign = (campaignId: number) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaign = await (
          await ApiController.getInstance().campaignApi.getCampaign(campaignId)
        ).data
        dispatch({
          type: ActionType.GET_CAMPAIGN,
          campaign: campaign,
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const createCampaign = (campaignObject: Campaign) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaign = await (
          await ApiController.getInstance().campaignApi.addCampaign(campaignObject)
        ).data
        dispatch({
          type: ActionType.ADD_CAMPAIGN,
          newCampaign: campaign,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Campaign created successfully',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const updateCampaign = (campaignObject: Campaign) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaign = await (
          await ApiController.getInstance().campaignApi.updateCampaign(campaignObject)
        ).data
        dispatch({
          type: ActionType.UPDATE_CAMPAIGN,
          updateCampaign: campaign,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Campaign updated successfully',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const sendCampaignAPI = (campaignId: number) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaign = await (
          await ApiController.getInstance().campaignApi.sendNotice(campaignId)
        ).data
        dispatch({
          type: ActionType.SEND_CAMPAIGN,
          sentCampaign: campaign,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_SUCCESS,
          message: 'Campaign sent successfully',
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const newCampaign = () => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.NEW_CAMPAIGN,
        })
        
      } catch (error: any) {
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const getCampaignOpenReport = (campaignId: number) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaign = await (
          await ApiController.getInstance().campaignApi.campaignOpen(campaignId)
        ).data
        dispatch({
          type: ActionType.GET_CAMPAIGN_OPEN_REPORT,
          campaign: campaign,
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }

  export const getCampaignSentReport = (campaignId: number) => {
    return async (dispatch: Dispatch<CampaignAction | SnackbarAction>) => {
      try {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: true,
        })
        const campaign = await (
          await ApiController.getInstance().campaignApi.campaignSent(campaignId)
        ).data
        dispatch({
          type: ActionType.GET_CAMPAIGN_SENT_REPORT,
          campaign: campaign,
        })
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
      } catch (error: any) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          shouldShowLoader: false,
        })
        dispatch({
          type: SnackbarActionType.SNACKBAR_FAILURE,
          message: error?.response?.data?.message,
        })
      }
    }
  }