import { Dispatch } from 'react'
import { ApiController } from '../../apiController'
import { NoticeTemplate } from '../../swagger/models'
import { ActionType, SnackbarActionType } from '../action-types'
import { TemplateAction, SnackbarAction } from '../actions'

export const listTemplates = () => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const templates = await (
        await ApiController.getInstance().templateApi.listTemplates()
      ).data
      dispatch({
        type: ActionType.LIST_TEMPLATES,
        templates: templates,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const recentTemplates = () => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const templates = await (
        await ApiController.getInstance().templateApi.recentTemplates()
      ).data
      dispatch({
        type: ActionType.RECENT_TEMPLATES,
        recentTemplates: templates,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const getTemplate = (noticeTemplateId: number) => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const template = await (
        await ApiController.getInstance().templateApi.getTemplate(noticeTemplateId)
      ).data
      dispatch({
        type: ActionType.GET_TEMPLATE,
        noticeTemplate: template,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const createTemplate = (noticeTemplate: NoticeTemplate) => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const template = await (
        await ApiController.getInstance().templateApi.addTemplate(noticeTemplate)
      ).data
      dispatch({
        type: ActionType.CREATE_TEMPLATE,
        createNoticeTemplate: template,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Template created successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const updateTemplate = (noticeTemplate: NoticeTemplate) => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const template = await (
        await ApiController.getInstance().templateApi.updateTemplate(noticeTemplate)
      ).data
      dispatch({
        type: ActionType.UPDATE_TEMPLATE,
        updateNoticeTemplate: template,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Template updated successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const sendTestTemplateAPI = (templateId: number, emailIds: string) => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      const campaign = await (
        await ApiController.getInstance().templateApi.sendTestNotice(templateId, emailIds)
      ).data
      dispatch({
        type: ActionType.SEND_TEST_TEMPLATE,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Test Notice sent successfully',
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteTemplate = (noticeTemplateId: number) => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().templateApi.deleteTemplate(noticeTemplateId)
      ).data
      dispatch({
        type: ActionType.DELETE_TEMPLATE,
        templateId: noticeTemplateId,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Notice deleted successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}

export const deleteTemplateQuestion = (questionId: number) => {
  return async (dispatch: Dispatch<TemplateAction | SnackbarAction>) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: true,
      })
      await (
        await ApiController.getInstance().templateApi.deleteTemplateQuestion(questionId)
      ).data
      dispatch({
        type: ActionType.DELETE_TEMPLATE_QUESTION,
        questionId: questionId,
      })
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_SUCCESS,
        message: 'Block deleted successfully!',
      })
    } catch (error: any) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        shouldShowLoader: false,
      })
      dispatch({
        type: SnackbarActionType.SNACKBAR_FAILURE,
        message: error?.response?.data?.message,
      })
    }
  }
}