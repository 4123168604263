/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Notice } from '../models';
import { NoticeComment } from '../models';
import { Reminder } from '../models';
/**
 * NoticeApi - axios parameter creator
 * @export
 */
export const NoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the details of organisation comment
         * @summary add comment specific details
         * @param {NoticeComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: async (body: NoticeComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addComment.');
            }
            const localVarPath = `/admin/notice-management/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of organisation comment.
         * @summary Delete comment specific details
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling deleteComment.');
            }
            const localVarPath = `/admin/notice-management/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (commentId !== undefined) {
                localVarQueryParameter['commentId'] = commentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get notice specific details
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotice: async (noticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            if (noticeId === null || noticeId === undefined) {
                throw new RequiredError('noticeId','Required parameter noticeId was null or undefined when calling getNotice.');
            }
            const localVarPath = `/admin/notice-management/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (noticeId !== undefined) {
                localVarQueryParameter['noticeId'] = noticeId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the notices inside organisation
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [language] 
         * @param {string} [noticeType] 
         * @param {string} [noticeStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotices: async (fromDate?: string, toDate?: string, language?: string, noticeType?: string, noticeStatus?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/notice-management/list_notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (noticeType !== undefined) {
                localVarQueryParameter['noticeType'] = noticeType;
            }

            if (noticeStatus !== undefined) {
                localVarQueryParameter['noticeStatus'] = noticeStatus;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the recent notices inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentNotices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/notice-management/recent_notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Reminder email to notice contact
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNoticeReminder: async (body: Reminder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendNoticeReminder.');
            }
            const localVarPath = `/admin/notice-management/send_notice_reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Reminder email to notice contacts in bulk
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {string} noticeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNoticeReminderBulk: async (body: Reminder, noticeIds: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendNoticeReminderBulk.');
            }
            // verify required parameter 'noticeIds' is not null or undefined
            if (noticeIds === null || noticeIds === undefined) {
                throw new RequiredError('noticeIds','Required parameter noticeIds was null or undefined when calling sendNoticeReminderBulk.');
            }
            const localVarPath = `/admin/notice-management/send_notice_reminder_bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (noticeIds !== undefined) {
                localVarQueryParameter['noticeIds'] = noticeIds;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Reminder email to notice contact
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {string} emailIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNoticeReminderPreview: async (body: Reminder, emailIds: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendNoticeReminderPreview.');
            }
            // verify required parameter 'emailIds' is not null or undefined
            if (emailIds === null || emailIds === undefined) {
                throw new RequiredError('emailIds','Required parameter emailIds was null or undefined when calling sendNoticeReminderPreview.');
            }
            const localVarPath = `/admin/notice-management/send_notice_reminder_preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (emailIds !== undefined) {
                localVarQueryParameter['emailIds'] = emailIds;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of organisation comment
         * @summary Update comment specific details
         * @param {NoticeComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (body: NoticeComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateComment.');
            }
            const localVarPath = `/admin/notice-management/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticeApi - functional programming interface
 * @export
 */
export const NoticeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add the details of organisation comment
         * @summary add comment specific details
         * @param {NoticeComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addComment(body: NoticeComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeComment>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).addComment(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of organisation comment.
         * @summary Delete comment specific details
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(commentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).deleteComment(commentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get notice specific details
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotice(noticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notice>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).getNotice(noticeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the notices inside organisation
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [language] 
         * @param {string} [noticeType] 
         * @param {string} [noticeStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotices(fromDate?: string, toDate?: string, language?: string, noticeType?: string, noticeStatus?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notice>>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).listNotices(fromDate, toDate, language, noticeType, noticeStatus, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the recent notices inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentNotices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notice>>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).recentNotices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send Reminder email to notice contact
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNoticeReminder(body: Reminder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reminder>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).sendNoticeReminder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send Reminder email to notice contacts in bulk
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {string} noticeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNoticeReminderBulk(body: Reminder, noticeIds: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).sendNoticeReminderBulk(body, noticeIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send Reminder email to notice contact
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {string} emailIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNoticeReminderPreview(body: Reminder, emailIds: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).sendNoticeReminderPreview(body, emailIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of organisation comment
         * @summary Update comment specific details
         * @param {NoticeComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(body: NoticeComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeComment>> {
            const localVarAxiosArgs = await NoticeApiAxiosParamCreator(configuration).updateComment(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NoticeApi - factory interface
 * @export
 */
export const NoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Add the details of organisation comment
         * @summary add comment specific details
         * @param {NoticeComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(body: NoticeComment, options?: any): AxiosPromise<NoticeComment> {
            return NoticeApiFp(configuration).addComment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of organisation comment.
         * @summary Delete comment specific details
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: number, options?: any): AxiosPromise<void> {
            return NoticeApiFp(configuration).deleteComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get notice specific details
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotice(noticeId: number, options?: any): AxiosPromise<Notice> {
            return NoticeApiFp(configuration).getNotice(noticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the notices inside organisation
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [language] 
         * @param {string} [noticeType] 
         * @param {string} [noticeStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotices(fromDate?: string, toDate?: string, language?: string, noticeType?: string, noticeStatus?: string, options?: any): AxiosPromise<Array<Notice>> {
            return NoticeApiFp(configuration).listNotices(fromDate, toDate, language, noticeType, noticeStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the recent notices inside organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentNotices(options?: any): AxiosPromise<Array<Notice>> {
            return NoticeApiFp(configuration).recentNotices(options).then((request) => request(axios, basePath));
        },
        /**
         * Send Reminder email to notice contact
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNoticeReminder(body: Reminder, options?: any): AxiosPromise<Reminder> {
            return NoticeApiFp(configuration).sendNoticeReminder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Send Reminder email to notice contacts in bulk
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {string} noticeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNoticeReminderBulk(body: Reminder, noticeIds: string, options?: any): AxiosPromise<void> {
            return NoticeApiFp(configuration).sendNoticeReminderBulk(body, noticeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Send Reminder email to notice contact
         * @summary get notice specific details
         * @param {Reminder} body 
         * @param {string} emailIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNoticeReminderPreview(body: Reminder, emailIds: string, options?: any): AxiosPromise<void> {
            return NoticeApiFp(configuration).sendNoticeReminderPreview(body, emailIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of organisation comment
         * @summary Update comment specific details
         * @param {NoticeComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(body: NoticeComment, options?: any): AxiosPromise<NoticeComment> {
            return NoticeApiFp(configuration).updateComment(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticeApi - object-oriented interface
 * @export
 * @class NoticeApi
 * @extends {BaseAPI}
 */
export class NoticeApi extends BaseAPI {
    /**
     * Add the details of organisation comment
     * @summary add comment specific details
     * @param {NoticeComment} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public addComment(body: NoticeComment, options?: any) {
        return NoticeApiFp(this.configuration).addComment(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of organisation comment.
     * @summary Delete comment specific details
     * @param {number} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public deleteComment(commentId: number, options?: any) {
        return NoticeApiFp(this.configuration).deleteComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of organisation user based on it's email id
     * @summary get notice specific details
     * @param {number} noticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getNotice(noticeId: number, options?: any) {
        return NoticeApiFp(this.configuration).getNotice(noticeId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the notices inside organisation
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [language] 
     * @param {string} [noticeType] 
     * @param {string} [noticeStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public listNotices(fromDate?: string, toDate?: string, language?: string, noticeType?: string, noticeStatus?: string, options?: any) {
        return NoticeApiFp(this.configuration).listNotices(fromDate, toDate, language, noticeType, noticeStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the recent notices inside organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public recentNotices(options?: any) {
        return NoticeApiFp(this.configuration).recentNotices(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send Reminder email to notice contact
     * @summary get notice specific details
     * @param {Reminder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public sendNoticeReminder(body: Reminder, options?: any) {
        return NoticeApiFp(this.configuration).sendNoticeReminder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send Reminder email to notice contacts in bulk
     * @summary get notice specific details
     * @param {Reminder} body 
     * @param {string} noticeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public sendNoticeReminderBulk(body: Reminder, noticeIds: string, options?: any) {
        return NoticeApiFp(this.configuration).sendNoticeReminderBulk(body, noticeIds, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Send Reminder email to notice contact
     * @summary get notice specific details
     * @param {Reminder} body 
     * @param {string} emailIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public sendNoticeReminderPreview(body: Reminder, emailIds: string, options?: any) {
        return NoticeApiFp(this.configuration).sendNoticeReminderPreview(body, emailIds, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of organisation comment
     * @summary Update comment specific details
     * @param {NoticeComment} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public updateComment(body: NoticeComment, options?: any) {
        return NoticeApiFp(this.configuration).updateComment(body, options).then((request) => request(this.axios, this.basePath));
    }
}
