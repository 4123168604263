import { User } from './../../swagger/models/user'
/* eslint-disable import/no-anonymous-default-export */

import { AuthAction, CommonAction } from '../actions/index'
import { ActionType } from '../action-types/index'

interface AuthUser {
  loggedInUser?: User
  passwordChanged?: boolean
  shouldShowLoader: boolean
}

const initialState = {
  loggedInUser: {},
  shouldShowLoader: false,
}

const authReducer = (state: AuthUser = initialState, action: CommonAction|AuthAction) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        loggedInUser: action.user,
      }
      case ActionType.UPDATE_PROFILE:
        return {
          ...state,
          loggedInUser: action.user,
        }
      case ActionType.CHANGE_PASSWORD:
        return {
          ...state,
          passwordChanged: true
        }
    default:
      return state
  }
}

export default authReducer
