/* eslint-disable import/no-anonymous-default-export */

import { DashboardAction } from '../actions/index'
import { Notice, NoticeDashboardReport } from '../../swagger/models'
import { ActionType } from '../action-types'

interface DashboardObject {
  notice?: Notice
  latestNoticesIssued?: Notice[]
  latestCampaignsIssued?: Notice[]
  noticeYearlyReport?: NoticeDashboardReport[]
  shouldShowLoader: boolean
}

const initialState = {
  notice: {},
  shouldShowLoader: false,
}

const dashboardReducer = (state: DashboardObject = initialState, action: DashboardAction) => {
  switch (action.type) {
    case ActionType.NOTICE_REPORT:
      return {
        ...state,
        noticeYearlyReport: action.noticeReports,
      };
    case ActionType.LATEST_CAMPAIGNS:
      return {
        ...state,
        latestCampaignsIssued: action.campaigns,
      };
    default:
      return state;
  }
}

export default dashboardReducer
