/* tslint:disable */
/* eslint-disable */
/**
 * Sama Notice Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Attachment } from '../models';
import { Folder } from '../models';
import { SubFolder } from '../models';
/**
 * FolderApi - axios parameter creator
 * @export
 */
export const FolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the details of organisation folder
         * @summary add folder specific details
         * @param {Folder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFolder: async (body: Folder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addFolder.');
            }
            const localVarPath = `/admin/folder-management/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Add the details of organisation sub folder
         * @summary add sub folder specific details
         * @param {SubFolder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubFolder: async (body: SubFolder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addSubFolder.');
            }
            const localVarPath = `/admin/folder-management/sub_folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the folders of an organisation
         * @summary Get all folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFolders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/folder-management/list_folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the sub folders of an organisation
         * @summary Get all folders
         * @param {number} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubfolders: async (folderId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/folder-management/list_subfolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the attachment of organisation inside sub folder.
         * @summary Delete subfolder specific attachment
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment: async (attachmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling deleteAttachment.');
            }
            const localVarPath = `/admin/folder-management/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (attachmentId !== undefined) {
                localVarQueryParameter['attachmentId'] = attachmentId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of organisation folder.
         * @summary Delete role specific details
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder: async (folderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new RequiredError('folderId','Required parameter folderId was null or undefined when calling deleteFolder.');
            }
            const localVarPath = `/admin/folder-management/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the details of organisation sub folder.
         * @summary Delete subfolder specific details
         * @param {number} subFolderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubFolder: async (subFolderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subFolderId' is not null or undefined
            if (subFolderId === null || subFolderId === undefined) {
                throw new RequiredError('subFolderId','Required parameter subFolderId was null or undefined when calling deleteSubFolder.');
            }
            const localVarPath = `/admin/folder-management/sub_folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subFolderId !== undefined) {
                localVarQueryParameter['subFolderId'] = subFolderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get role specific details
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder: async (folderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new RequiredError('folderId','Required parameter folderId was null or undefined when calling getFolder.');
            }
            const localVarPath = `/admin/folder-management/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get sub folder specific details
         * @param {number} subFolderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubFolder: async (subFolderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subFolderId' is not null or undefined
            if (subFolderId === null || subFolderId === undefined) {
                throw new RequiredError('subFolderId','Required parameter subFolderId was null or undefined when calling getSubFolder.');
            }
            const localVarPath = `/admin/folder-management/sub_folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subFolderId !== undefined) {
                localVarQueryParameter['subFolderId'] = subFolderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the attachments of an organisation
         * @summary Get all folders
         * @param {number} [folderId] 
         * @param {number} [subFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAttachments: async (folderId?: number, subFolderId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/folder-management/list_attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (subFolderId !== undefined) {
                localVarQueryParameter['subFolderId'] = subFolderId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of organisation folder
         * @summary Update folder specific details
         * @param {Folder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder: async (body: Folder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateFolder.');
            }
            const localVarPath = `/admin/folder-management/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the details of organisation sub folder
         * @summary Update sub folder specific details
         * @param {SubFolder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubFolder: async (body: SubFolder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSubFolder.');
            }
            const localVarPath = `/admin/folder-management/sub_folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Attachment upload for organisation
         * @summary upload Attachment of the organisation
         * @param {string} profilePic 
         * @param {number} [folderId] 
         * @param {number} [subFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment: async (profilePic: string, folderId?: number, subFolderId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profilePic' is not null or undefined
            if (profilePic === null || profilePic === undefined) {
                throw new RequiredError('profilePic','Required parameter profilePic was null or undefined when calling uploadAttachment.');
            }
            const localVarPath = `/admin/folder-management/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (subFolderId !== undefined) {
                localVarQueryParameter['subFolderId'] = subFolderId;
            }


            if (profilePic !== undefined) { 
                localVarFormParams.append('profilePic', profilePic as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderApi - functional programming interface
 * @export
 */
export const FolderApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add the details of organisation folder
         * @summary add folder specific details
         * @param {Folder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFolder(body: Folder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).addFolder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add the details of organisation sub folder
         * @summary add sub folder specific details
         * @param {SubFolder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSubFolder(body: SubFolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubFolder>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).addSubFolder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all the folders of an organisation
         * @summary Get all folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allFolders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Folder>>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).allFolders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all the sub folders of an organisation
         * @summary Get all folders
         * @param {number} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubfolders(folderId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubFolder>>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).allSubfolders(folderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the attachment of organisation inside sub folder.
         * @summary Delete subfolder specific attachment
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachment(attachmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).deleteAttachment(attachmentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of organisation folder.
         * @summary Delete role specific details
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolder(folderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).deleteFolder(folderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the details of organisation sub folder.
         * @summary Delete subfolder specific details
         * @param {number} subFolderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubFolder(subFolderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).deleteSubFolder(subFolderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get role specific details
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolder(folderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).getFolder(folderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get sub folder specific details
         * @param {number} subFolderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubFolder(subFolderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubFolder>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).getSubFolder(subFolderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all the attachments of an organisation
         * @summary Get all folders
         * @param {number} [folderId] 
         * @param {number} [subFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAttachments(folderId?: number, subFolderId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubFolder>>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).listAttachments(folderId, subFolderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of organisation folder
         * @summary Update folder specific details
         * @param {Folder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolder(body: Folder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).updateFolder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the details of organisation sub folder
         * @summary Update sub folder specific details
         * @param {SubFolder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubFolder(body: SubFolder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubFolder>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).updateSubFolder(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Attachment upload for organisation
         * @summary upload Attachment of the organisation
         * @param {string} profilePic 
         * @param {number} [folderId] 
         * @param {number} [subFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAttachment(profilePic: string, folderId?: number, subFolderId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await FolderApiAxiosParamCreator(configuration).uploadAttachment(profilePic, folderId, subFolderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FolderApi - factory interface
 * @export
 */
export const FolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Add the details of organisation folder
         * @summary add folder specific details
         * @param {Folder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFolder(body: Folder, options?: any): AxiosPromise<Folder> {
            return FolderApiFp(configuration).addFolder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Add the details of organisation sub folder
         * @summary add sub folder specific details
         * @param {SubFolder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubFolder(body: SubFolder, options?: any): AxiosPromise<SubFolder> {
            return FolderApiFp(configuration).addSubFolder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the folders of an organisation
         * @summary Get all folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFolders(options?: any): AxiosPromise<Array<Folder>> {
            return FolderApiFp(configuration).allFolders(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the sub folders of an organisation
         * @summary Get all folders
         * @param {number} [folderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubfolders(folderId?: number, options?: any): AxiosPromise<Array<SubFolder>> {
            return FolderApiFp(configuration).allSubfolders(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the attachment of organisation inside sub folder.
         * @summary Delete subfolder specific attachment
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment(attachmentId: number, options?: any): AxiosPromise<void> {
            return FolderApiFp(configuration).deleteAttachment(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of organisation folder.
         * @summary Delete role specific details
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(folderId: number, options?: any): AxiosPromise<void> {
            return FolderApiFp(configuration).deleteFolder(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the details of organisation sub folder.
         * @summary Delete subfolder specific details
         * @param {number} subFolderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubFolder(subFolderId: number, options?: any): AxiosPromise<void> {
            return FolderApiFp(configuration).deleteSubFolder(subFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get role specific details
         * @param {number} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(folderId: number, options?: any): AxiosPromise<Folder> {
            return FolderApiFp(configuration).getFolder(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of organisation user based on it's email id
         * @summary get sub folder specific details
         * @param {number} subFolderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubFolder(subFolderId: number, options?: any): AxiosPromise<SubFolder> {
            return FolderApiFp(configuration).getSubFolder(subFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the attachments of an organisation
         * @summary Get all folders
         * @param {number} [folderId] 
         * @param {number} [subFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAttachments(folderId?: number, subFolderId?: number, options?: any): AxiosPromise<Array<SubFolder>> {
            return FolderApiFp(configuration).listAttachments(folderId, subFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of organisation folder
         * @summary Update folder specific details
         * @param {Folder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder(body: Folder, options?: any): AxiosPromise<Folder> {
            return FolderApiFp(configuration).updateFolder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the details of organisation sub folder
         * @summary Update sub folder specific details
         * @param {SubFolder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubFolder(body: SubFolder, options?: any): AxiosPromise<SubFolder> {
            return FolderApiFp(configuration).updateSubFolder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Attachment upload for organisation
         * @summary upload Attachment of the organisation
         * @param {string} profilePic 
         * @param {number} [folderId] 
         * @param {number} [subFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment(profilePic: string, folderId?: number, subFolderId?: number, options?: any): AxiosPromise<Attachment> {
            return FolderApiFp(configuration).uploadAttachment(profilePic, folderId, subFolderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderApi - object-oriented interface
 * @export
 * @class FolderApi
 * @extends {BaseAPI}
 */
export class FolderApi extends BaseAPI {
    /**
     * Add the details of organisation folder
     * @summary add folder specific details
     * @param {Folder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public addFolder(body: Folder, options?: any) {
        return FolderApiFp(this.configuration).addFolder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Add the details of organisation sub folder
     * @summary add sub folder specific details
     * @param {SubFolder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public addSubFolder(body: SubFolder, options?: any) {
        return FolderApiFp(this.configuration).addSubFolder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all the folders of an organisation
     * @summary Get all folders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public allFolders(options?: any) {
        return FolderApiFp(this.configuration).allFolders(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all the sub folders of an organisation
     * @summary Get all folders
     * @param {number} [folderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public allSubfolders(folderId?: number, options?: any) {
        return FolderApiFp(this.configuration).allSubfolders(folderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the attachment of organisation inside sub folder.
     * @summary Delete subfolder specific attachment
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public deleteAttachment(attachmentId: number, options?: any) {
        return FolderApiFp(this.configuration).deleteAttachment(attachmentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of organisation folder.
     * @summary Delete role specific details
     * @param {number} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public deleteFolder(folderId: number, options?: any) {
        return FolderApiFp(this.configuration).deleteFolder(folderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the details of organisation sub folder.
     * @summary Delete subfolder specific details
     * @param {number} subFolderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public deleteSubFolder(subFolderId: number, options?: any) {
        return FolderApiFp(this.configuration).deleteSubFolder(subFolderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of organisation user based on it's email id
     * @summary get role specific details
     * @param {number} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public getFolder(folderId: number, options?: any) {
        return FolderApiFp(this.configuration).getFolder(folderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of organisation user based on it's email id
     * @summary get sub folder specific details
     * @param {number} subFolderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public getSubFolder(subFolderId: number, options?: any) {
        return FolderApiFp(this.configuration).getSubFolder(subFolderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all the attachments of an organisation
     * @summary Get all folders
     * @param {number} [folderId] 
     * @param {number} [subFolderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public listAttachments(folderId?: number, subFolderId?: number, options?: any) {
        return FolderApiFp(this.configuration).listAttachments(folderId, subFolderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of organisation folder
     * @summary Update folder specific details
     * @param {Folder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public updateFolder(body: Folder, options?: any) {
        return FolderApiFp(this.configuration).updateFolder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the details of organisation sub folder
     * @summary Update sub folder specific details
     * @param {SubFolder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public updateSubFolder(body: SubFolder, options?: any) {
        return FolderApiFp(this.configuration).updateSubFolder(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Attachment upload for organisation
     * @summary upload Attachment of the organisation
     * @param {string} profilePic 
     * @param {number} [folderId] 
     * @param {number} [subFolderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public uploadAttachment(profilePic: string, folderId?: number, subFolderId?: number, options?: any) {
        return FolderApiFp(this.configuration).uploadAttachment(profilePic, folderId, subFolderId, options).then((request) => request(this.axios, this.basePath));
    }
}
